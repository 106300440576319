table {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 80%;
}

table td,
table th {
	border: 2px solid black;
	padding: 8px;
	text-align: left;
	font-size: 15px;
	line-height: 20px;
}

table tr:hover {
	background-color: #ffee01;
	cursor: default;
}

table th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
	background-color: #9d0000;
	color: white;
	font-size: 20px;
}
