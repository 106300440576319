.App {
  text-align: center;
}

.Table {
  font-size: 10px;
  padding-top: 20px;
  padding-left: 16%;
  padding-bottom: 80px;
}

input {
  height: 30px;
  width: 150px;
  font-size: 15px;
}

.Heading {
  font-size: 25px;
}

.Sub-Heading {
  font-size: 18px;
}